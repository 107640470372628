import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: { title: '潮社赏' }
	},
	{
		path: '/agreement/:key',
		name: 'agreement',
		component: () => import('@/views/agreement/index.vue'),
		meta: { title: '潮社赏-协议' }
	},
	{
		path: '/agreement',
		name: 'agreement_1',
		component: () => import('@/views/agreement/index1.vue'),
		meta: { title: '潮社赏-协议' }
	},
	{
		path: '*',
		redirect: '/'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.title
	next()
})

export default router
